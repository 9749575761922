<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      Tất cả bản quyền từ năm 2021 thuộc về -
      <b-link
        class="ml-25"
        :href="href"
        target="_blank"
      > Sở Lao động Thương binh và Xã hội tỉnh {{ province }}</b-link>
      v1.08.23.1
    </span>

  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import store from '@/home/store'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      province: store.province,
      href: store.hrefDistrict,
    }
  },
}
</script>
